import React, { useContext } from 'react'
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import colors from '../styles/colors'
import { GlobalStateContext } from '../context/GlobalContextProvider'
import Layout from '../components/layout'

import SEO from '../components/seo'

// Provide common components here
const shortcodes = {
  Link,
  a: props => <a {...props} target="_blank" rel="noopener"/>,
}

export const pageQuery = graphql`
  query BlogPostQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        summary
        date
        tags
      }
    }
  }
`

const BlogTitle = styled.h1`
  color: ${props => props.theme.accent}
`

const Blog = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 750px;

  margin-top: 2rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 750px) {
    width: 100vw;
  }

  line-height: 1.5;

  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  text-rendering: optimizeLegibility;

  * {
    ::-moz-selection {
      color: black;
      background: ${props => props.theme.accent};
    }

    ::selection {
      color: black;
      background: ${props => props.theme.accent};
    }
  }

  h1,
  h2 {
    padding: 1rem 0.2rem;
  }

  code,
  pre {
    color: ${props => props.theme.text};
  }

  code {
    padding: 2px 4px;
    vertical-align: text-bottom;
  }

  canvas {
    width: 100vw;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  pre {
    padding: 1em;
    border-left: 2px solid ${props => props.theme.accent};

    background: ${props => props.theme.shadow};

    white-space: pre-wrap;       /* css-3 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
  }

  a {
    color: ${props => props.theme.accent};
  }

  ul {
    list-style-position: outside;
    padding: 0.5rem;
    margin-left: 1rem;
  }

  p {
    padding: 0.5rem;
    line-height: 1.5;
  }

  blockquote {
    border-left: ${props => `5px solid ${props.theme.text}`};
    background: ${props => props.theme.shadow};
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  img {
    border: 5px solid ${props => props.theme.accent};
  }

`

const PageTemplate = ({ data: { mdx } }) => {
  const state = useContext(GlobalStateContext)
  const theme = colors[state.theme]

  return (
    <Layout>
      <SEO
        title={mdx.frontmatter.title}
        description={mdx.frontmatter.summary}
        keywords={mdx.frontmatter.tags}
      />
      <Blog theme={theme}>
        <BlogTitle theme={theme}>{mdx.frontmatter.title}</BlogTitle>
        <MDXProvider components={shortcodes}>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Blog>
    </Layout>
  )
}

export default PageTemplate
